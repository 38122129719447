import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { REVIEW_RATINGS } from '../../util/types';

import { IconReviewStar } from '..';

const AvgReviewRating = props => {
  const { className, rootClassName, reviewStarClassName, ratings, customRating } = props;
  const classes = classNames(rootClassName, className);
  const [rating, setRating] = useState(0);
  const calculateRating = () => {
    const ratingCount = ratings.length || 0;
    let totalRatings = 0;
    ratings.map(r => {
      totalRatings += +r.attributes.rating;
    });
    const avgRating = ratingCount > 0 ? totalRatings / ratingCount : 0;
    setRating(avgRating);
  };

  useState(() => {
    if (!customRating) {
      calculateRating();
    }
  }, []);
  const stars = REVIEW_RATINGS;
  return (
    <>
      {customRating ? (
        <span className={classes} title={`${+customRating}/5`}>
          <span>{(+customRating)?.toFixed(1)}</span>
          {REVIEW_RATINGS.map(star => (
            <IconReviewStar
              key={`star-${star}`}
              className={reviewStarClassName}
              isFilled={star <= +customRating}
            />
          ))}
        </span>
      ) : (
        <span className={classes} title={`${rating}/5`}>
          <span>{rating > 0 ? rating?.toFixed(1) : '0.0'}</span>
          {stars.map(star => (
            <IconReviewStar
              key={`star-${star}`}
              className={reviewStarClassName}
              isFilled={star <= rating}
            />
          ))}
        </span>
      )}
    </>
  );
};

AvgReviewRating.defaultProps = {
  rootClassName: null,
  className: null,
  reviewStarClassName: null,
};

const { string, oneOf } = PropTypes;

AvgReviewRating.propTypes = {
  ratings: REVIEW_RATINGS,
  reviewStartClassName: string,
  rootClassName: string,
  className: string,
};

export default AvgReviewRating;
